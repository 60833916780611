<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/inventory-transaction?transaction_number='+search.transaction_number+'&transaction_type='+search.transaction_type+'&po_number='+search.po_number+'&delivery_order_number='+search.delivery_order_number+'&supplier='+search.supplier['name']+'&project='+search.project['project_number']+'&user='+search.user['name']+'&item_name='+search.item_name['item_name']+'&reducted_select='+search.reducted_select+'&category_reduction='+search.category_reduction+'&pic_warehouse='+search.pic_warehouse['name']+'&warehouse_location='+search.warehouse_location['name']+'&date='+search.date+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Inventory Transactions</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Transaction Number</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Transaction Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Additional Info</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Warehouse location</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PIC Warehouse</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Total Item</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Date</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <router-link :to="{ name: 'TransactionInventoryDetail', params: { type: row.transaction_type, code: row.transaction_number }}" v-if="row.transaction_type != 'Reduction'">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.transaction_number }}</span>
                        </router-link>
                        <span class="badge badge-sm bg-gradient-primary" v-else>{{ row.transaction_number }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.transaction_type }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <template v-if="row.transaction_type == 'Reciev New'">
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PO NUMBER : </span> {{ row.po_number }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">DELIVERY ORDER NUMBER : </span> {{ row.delivery_order_number }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">SUPPLIER : </span> {{ row.supplier }}</span><br>
                        </template>
                        <template v-if="row.transaction_type == 'Usage / Loan' || row.transaction_type == 'Return'">
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PROJECT : </span> {{ row.project }} - {{ row.project_name }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">USER : </span> {{ row.user }}</span><br>
                        </template>
                        <template v-if="row.transaction_type == 'Reduction'">
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ITEM NAME : </span> {{ row.item_name }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">QUANTITY : </span> {{ row.reduction_qty }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">REDUCED SELECT : </span> {{ row.reducted_select }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">REDUCTION CATEGORY : </span> {{ row.reduction_category }}</span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ATTACHMENT : </span> <a :href="storageUrl+'/attachment_inv_transaction/'+row.attachment" target="_BLANK" style="color: blue;">See Attachment</a></span><br>
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">USER : </span> {{ row.user }}</span><br>
                        </template>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.warehouse_location }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.pic_warehouse }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.total_item_count }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info" v-if="row.status == 'Draf'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'Completed'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.date).locale('us').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fa fa-check-circle-o" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="fixData(row.transaction_number)" title="Fixed Data" v-if="row.status == 'Draf' && row.transaction_type == 'Reduction'"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id, row.total_item_count, row.transaction_type)" v-if="row.status == 'Draf'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- ===========================================  MODAL ADD DATA =============================================== -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Transaction Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="transactionData.transaction_type" :disabled="form.title != 'Add Data'">
          <option selected>Transaction Type</option>
          <option value="Reciev New">Reciev New</option>
          <option value="Usage / Loan">Usage / Loan</option>
          <option value="Return">Return</option>
          <option value="Reduction" v-if="position == 'Manager'">Reduction</option>
        </select>

          <!-- TRANSACTION RECIEV NEW -->
          <template v-if="transactionData.transaction_type == 'Reciev New'">
            <label for="example-text-input" class="form-control-label mt-3">PO Number <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="PO Number" v-model="transactionData.po_number">
            <label for="example-text-input" class="form-control-label mt-3">Delivery Order Number <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="Delivery Order Number" v-model="transactionData.delivery_order_number">
            <label for="example-text-input" class="form-control-label mt-3">Supplier <span style="color: red;">*</span></label>
            <multiselect v-model="transactionData.supplier" :options="tableSupplier.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Supplier" label="name" track-by="name" :preselect-first="true" @keyup="getSupplier">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION RECIEV NEW END -->

          <!-- TRANSACTION USAGE / LOAN or RETURN -->
          <template v-if="transactionData.transaction_type == 'Usage / Loan' || transactionData.transaction_type == 'Return'">
            <label for="example-text-input" class="form-control-label mt-3">Project <span style="color: red;">*</span></label>
            <multiselect v-model="transactionData.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION USAGE / LOAN or RETURN END -->

          <label for="example-text-input" class="form-control-label mt-3">Warehouse Location <span style="color: red;">*</span></label>
          <multiselect v-model="transactionData.warehouse_location" :options="tableWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Warehouse Location" label="name" track-by="name" :preselect-first="true" @keyup="getWarehouseLocation">
            <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
          </multiselect>
          
          <!-- TRANSACTION REDUCTION -->
          <template v-if="transactionData.transaction_type == 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">Item Name <span style="color: red;">*</span></label>
            <multiselect v-model="transactionData.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getItem" :custom-label="nameWithLangItem" :disabled="!transactionData.warehouse_location">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Select Reduction <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="transactionData.reducted_select" @click="getItemCategory, transactionData.qty = ''" :disabled="!transactionData.item_name || itemCategory == 'Consumable'">
              <option selected>Select Reduction</option>
              <option value="Quantity">Quantity</option>
              <option value="Quantity On Loan">Quantity On Loan</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3" >Qty <span style="color: red;">*</span></label>
            <input type="number" class="form-control" placeholder="Qty" v-model="transactionData.qty" @click="setMaxQty" :max="qtyMax" min="0" :disabled="!transactionData.item_name">
            <small style="color: red; font-size: 12px;" v-if="qtyMax">Qty Maximum {{ qtyMax }}</small><br>
            <label for="example-text-input" class="form-control-label mt-3">Reduction Category <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="transactionData.category_reduction">
              <option selected>Select Reduction Category</option>
              <option value="Lost">Lost</option>
              <option value="Disposal">Disposal</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3" >Attachment <span style="color: red;">*</span></label>
            <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange">
          </template>
          <!-- TRANSACTION REDUCTION END -->

          <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION -->
          <template v-if="transactionData.transaction_type == 'Usage / Loan' || transactionData.transaction_type == 'Return' || transactionData.transaction_type == 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">User <span style="color: red;">*</span></label>
            <multiselect v-model="transactionData.user" :options="tableUser.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Users" label="name" track-by="name" :preselect-first="true" @keyup="getUser">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION END -->

          <!-- HILANGKAN PIC WAREHOUSE JIKA TRANSACTION REDUCTION -->
          <template v-if="transactionData.transaction_type != 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">PIC Warehouse <span style="color: red;">*</span></label>
            <multiselect v-model="transactionData.pic_warehouse" :options="tablePicWarehouse.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC Warehouse" label="name" track-by="name" :preselect-first="true" @keyup="getPicWarehouse">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>

          <label for="example-text-input" class="form-control-label mt-3">Date <span style="color: red;">*</span></label>
          <input type="date" class="form-control" placeholder="Date" v-model="transactionData.date">

      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="transactionData = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- ================================================  MODAL FILTER ============================================== -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Transaction Number</label>
        <input type="text" class="form-control" placeholder="Transaction Number" v-model="search.transaction_number" v-on:keyup.enter="get()">

        <label for="example-text-input" class="form-control-label mt-3">Transaction Type</label>
        <select class="form-select" aria-label="Default select example" v-model="search.transaction_type">
          <option selected>Transaction Type</option>
          <option value="Reciev New">Reciev New</option>
          <option value="Usage / Loan">Usage / Loan</option>
          <option value="Return">Return</option>
          <option value="Reduction">Reduction</option>
        </select>

        <!-- TRANSACTION RECIEV NEW -->
          <template v-if="search.transaction_type == 'Reciev New'">
            <label for="example-text-input" class="form-control-label mt-3">PO Number </label>
            <input type="text" class="form-control" placeholder="PO Number" v-model="search.po_number">
            <label for="example-text-input" class="form-control-label mt-3">Delivery Order Number </label>
            <input type="text" class="form-control" placeholder="Delivery Order Number" v-model="search.delivery_order_number">
            <label for="example-text-input" class="form-control-label mt-3">Supplier </label>
            <multiselect v-model="search.supplier" :options="tableSupplier.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Supplier" label="name" track-by="name" :preselect-first="true" @keyup="getSupplier">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION RECIEV NEW END -->

          <!-- TRANSACTION USAGE / LOAN or RETURN -->
          <template v-if="search.transaction_type == 'Usage / Loan' || search.transaction_type == 'Return'">
            <label for="example-text-input" class="form-control-label mt-3">Project </label>
            <multiselect v-model="search.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION USAGE / LOAN or RETURN END -->

          <label for="example-text-input" class="form-control-label mt-3">Warehouse Location </label>
          <multiselect v-model="search.warehouse_location" :options="tableWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Warehouse Location" label="name" track-by="name" :preselect-first="true" @keyup="getWarehouseLocation">
            <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
          </multiselect>
          
          <!-- TRANSACTION REDUCTION -->
          <template v-if="search.transaction_type == 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">Item Name </label>
            <multiselect v-model="search.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getItem" :custom-label="nameWithLangItem">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Select Reduction </label>
            <select class="form-select" aria-label="Default select example" v-model="search.reducted_select">
              <option selected>Select Reduction</option>
              <option value="Quantity">Quantity</option>
              <option value="Quantity On Loan">Quantity On Loan</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Reduction Category </label>
            <select class="form-select" aria-label="Default select example" v-model="search.category_reduction">
              <option selected>Select Reduction Category</option>
              <option value="Lost">Lost</option>
              <option value="Disposal">Disposal</option>
            </select>
          </template>
          <!-- TRANSACTION REDUCTION END -->

          <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION -->
          <template v-if="search.transaction_type == 'Usage / Loan' || search.transaction_type == 'Return' || search.transaction_type == 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">User </label>
            <multiselect v-model="search.user" :options="tableUser.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Users" label="name" track-by="name" :preselect-first="true" @keyup="getUser">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION END -->

          <!-- HILANGKAN PIC WAREHOUSE JIKA TRANSACTION REDUCTION -->
          <template v-if="search.transaction_type != 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">PIC Warehouse </label>
            <multiselect v-model="search.pic_warehouse" :options="tablePicWarehouse.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC Warehouse" label="name" track-by="name" :preselect-first="true" @keyup="getPicWarehouse">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>

          <label for="example-text-input" class="form-control-label mt-3">Date </label>
          <input type="date" class="form-control" placeholder="PO Number" v-model="search.date">

      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChange" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Data Users.xlsx'" style="color: blue;"> Import Data Setting Approval</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center;">Column</th>
                  <th style="font-size: 13px; text-align: center;">Error</th>
                  <th style="font-size: 13px; text-align: center;">Row</th>
                  <th style="font-size: 13px; text-align: center;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import transactionInventory from '@/services/transactionInventory.service';
var moment = require('moment');
import supplier from '@/services/supplier.service';
import warehouseLocation from '@/services/warehouseLocation.service';
import employee from '@/services/employee.service';
import projectList from '@/services/projectList.service';
import inventoryList from '@/services/inventoryList.service';
import akun from '@/services/akun.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      transactionData: {},
      search: {
        transaction_number: '',
        transaction_type: '',
        po_number: '',
        delivery_order_number: '',
        supplier: '',
        project: '',
        user: '',
        item_name: '',
        reducted_select: '',
        category_reduction: '',
        pic_warehouse: '',
        warehouse_location: '',
        date: '',
        status: '',
      },
      apiUrl :config.apiUrl,
      tableSupplier: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableUser: {
        data: []
      },
      tableWarehouseLocation: {
        data: []
      },
      tablePicWarehouse: {
        data: []
      },
      tableItem: {
        data: []
      },
      qtyMax: '',
      itemCategory: '',
      position: '',
    };
  },
  mounted(){
    this.get();
    this.getPositionUser();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} - ${project_name}`
    },
    nameWithLangItem ({ code, name }) {
      return `${code} - ${name}`
    },
    get(param){
      let context = this; 
      context.onLoading = true;

      Api(context, transactionInventory.index({transaction_number: this.search.transaction_number, transaction_type: this.search.transaction_type, po_number: this.search.po_number, delivery_order_number: this.search.delivery_order_number, supplier: this.search.supplier['name'], project: this.search.project['project_number'] , user: this.search.user['name'], item_name: this.search.item_name['item_name'], reducted_select: this.search.reducted_select, category_reduction: this.search.category_reduction, pic_warehouse: this.search.pic_warehouse['name'], warehouse_location: this.search.warehouse_location['name'], date: this.search.date, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show = false;
         context.onLoading       = false;
      })
      .call()
    },
    getSupplier(param){
      let context = this;        
      Api(context, supplier.getSupplier({search: param.target.value})).onSuccess(function(response) {    
          context.tableSupplier.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableSupplier.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getProject(param){
      let context = this; 
      Api(context, projectList.getProject({search: param.target.value})).onSuccess(function(response) {    
      // console.log(response.data.data)       
          context.tableProject.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableProject.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getUser(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableUser.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableUser.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getWarehouseLocation(param){
      let context = this;        
      Api(context, warehouseLocation.getWarehouseLocation({search: param.target.value})).onSuccess(function(response) {    
          context.tableWarehouseLocation.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableWarehouseLocation.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPicWarehouse(param){
      let context = this;        
      Api(context, employee.getPicWarehouse({search: param.target.value})).onSuccess(function(response) {    
          context.tablePicWarehouse.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePicWarehouse.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getItem(param){
      let context = this;        
      Api(context, inventoryList.getItem({item_name: param.target.value, type_transaction: context.transactionData.transaction_type, warehouse_location: context.transactionData.warehouse_location['name'], user: context.transactionData.user, project: ''})).onSuccess(function(response) {    
          context.tableItem.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPositionUser(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.position = response.data.data[0].position;
      }).onError(function(error) {  
      })
      .call() 
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add                        = true;
      this.form.show                       = true;
      this.form.title                      = "Add Data";
      this.transactionData                 = {};
      this.transactionData.reducted_select = 'Quantity',
      this.defaultDate()
    },
    edit(id) {
      let context = this;               
      Api(context, transactionInventory.show(id)).onSuccess(function(response) {
          context.transactionData                    = response.data.data.data;
          context.transactionData.uom                = response.data.data.dataUom
          context.transactionData.warehouse_location = response.data.data.dataWL
          context.form.show                            = true;
          context.form.title                           = 'Edit Data';   
      })
      .call()        
    },
    filesChange(e) {
      this.transactionData.attachment = e.target.files[0];
    },
    save(){
      let api           = null;
      let context       = this;
      let formData      = new FormData();
      context.onLoading = true;

      if (context.transactionData.transaction_type != undefined && context.transactionData.warehouse_location != undefined && context.transactionData.date != undefined) {
        formData.append('transaction_type', context.transactionData.transaction_type);
        formData.append('warehouse_location', JSON.stringify(context.transactionData.warehouse_location));
        formData.append('date', context.transactionData.date);

        // Reciev New
        if (context.transactionData.transaction_type == 'Reciev New') {
          if (context.transactionData.po_number != undefined && context.transactionData.delivery_order_number != undefined && context.transactionData.supplier != undefined) {
            formData.append('po_number', context.transactionData.po_number);
            formData.append('delivery_order_number', context.transactionData.delivery_order_number);
            formData.append('supplier', JSON.stringify(context.transactionData.supplier));
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // Usage / Loan Or Return
        if (context.transactionData.transaction_type == 'Usage / Loan' || context.transactionData.transaction_type == 'Return') {
          if (context.transactionData.project != undefined) {
            formData.append('project', JSON.stringify(context.transactionData.project));
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // Reduction
        if (context.transactionData.transaction_type == 'Reduction') {
          if (context.transactionData.item_name != undefined && context.transactionData.qty != undefined && context.transactionData.category_reduction != undefined && context.transactionData.attachment != undefined) {
            formData.append('item_name', JSON.stringify(context.transactionData.item_name));
            formData.append('reducted_select', context.transactionData.reducted_select);
            formData.append('qty', context.transactionData.qty);
            formData.append('category_reduction', context.transactionData.category_reduction);
            formData.append('attachment', context.transactionData.attachment);
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // Usage / Loan Or Return
        if (context.transactionData.transaction_type == 'Usage / Loan' || context.transactionData.transaction_type == 'Return' || context.transactionData.transaction_type == 'Reduction') {
          if (context.transactionData.user != undefined) {
            formData.append('user', JSON.stringify(context.transactionData.user));
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // HILANGKAN PIC WAREHOUSE JIKA TRANSACTION REDUCTION
        if (context.transactionData.transaction_type != 'Reduction') {
          if (context.transactionData.pic_warehouse != undefined) {
            formData.append('pic_warehouse', JSON.stringify(context.transactionData.pic_warehouse));
          }
        }

      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, transactionInventory.create(formData));
      } else {
          api = Api(context, transactionInventory.update(this.transactionData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id, total_item, transaction_type) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        if ((transaction_type == 'Reduction') || (total_item == 0)) {
          let context = this;
          Api(context, transactionInventory.delete(id)).onSuccess(function(response) {
              context.get();
              context.notify('Data Successfully Deleted', 'success')
          }).call();
        }else{
          this.notify('Failed to delete. Please clear items first', 'error')
        }
      }
    },
    fixData(param){
      let api      = null;
      let context  = this;

      api = Api(context, transactionInventory.fixData({transaction_number: param}));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    setMaxQty(){
      if (this.transactionData.item_name['category'] != 'Consumable' && this.transactionData.reducted_select == 'Quantity On Loan') {
        this.qtyMax = JSON.stringify(Number(this.transactionData.item_name['total_qty_item_on_loan_count']))
      }else{
        this.qtyMax = JSON.stringify(Number(this.transactionData.item_name['total_qty_item_count']))
      }
    },
    getItemCategory(){
      this.itemCategory = this.transactionData.item_name['category']
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, transactionInventory.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.transactionData.date = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
