<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/med-reimbush-glasses?claim_number='+search.claim_number+'&employee='+search.employee['nik']+'&submission_date='+search.submission_date+'&receipt_date='+search.receipt_date+'&is_urgent='+search.is_urgent+'&is_closed='+search.is_closed+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-warning" style="margin-right: 10px;" :href="apiUrl+'print-pdf/med-reimbush-glasses?claim_number='+search.claim_number+'&employee='+search.employee['nik']+'&submission_date='+search.submission_date+'&receipt_date='+search.receipt_date+'&is_urgent='+search.is_urgent+'&is_closed='+search.is_closed+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Glasses Reimbushment</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Employee</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Date </th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Claim Number</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Price Glasses</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Diagnosis</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Is Urgent</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Is Closed</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.employee_name }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">Submission : </span><br>  {{ moment(row.submission_date).locale('id').format('ll') }} WIB </span><br>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">Receipt : </span><br>  {{ moment(row.receipt_date).locale('id').format('ll') }} WIB </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">{{ row.claim_number }} </span></span><br>
                          <template v-if="row.attachment">
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ATTACHMENT &nbsp;:&nbsp; </span> <a :href="storageUrl+'/attachment_rembushment_glasses/'+row.attachment" target="_BLANK" style="color: blue;">See Attachment</a></span><br>
                          </template>
                          <template v-else>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-danger">NO ATTACHMENT </span></span>
                          </template>
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold"> Rp. {{ convertRp(row.price) }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ row.diagnosis }}
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-primary" v-if="row.is_urgent == 'Yes'">Yes</span>
                        <span class="badge badge-sm bg-gradient-danger" v-if="row.is_urgent == 'No'">No</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-primary" v-if="row.is_closed == 'Yes'">Yes</span>
                        <span class="badge badge-sm bg-gradient-danger" v-if="row.is_closed == 'No'">No</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fa fa-check-circle-o" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="closed(row.id)" title="Closed"></i>
                        <a style="margin-right: 20px;" :href="apiUrl+'print-pdf/print-reimbushment-glasses-where-id?id='+row.id+''" target="_BLANK"><i class="fa fa-print" title="Print"></i></a>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Delete" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <!-- <span class="font-weight-bold text-danger ">TOTAL WORKING HOUR : {{ totalWorkingHour }} Hour {{ totalWorkingMinutes }} Minutes</span> -->
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee <span style="color: red;">*</span></label>
         <multiselect v-model="medReimbushGlassesData.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Submission Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Submission Date" v-model="medReimbushGlassesData.submission_date">
        <label for="example-text-input" class="form-control-label mt-3">Receipt Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Receipt Date" v-model="medReimbushGlassesData.receipt_date">
        <label for="example-text-input" class="form-control-label mt-3">Price <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Price" v-model="medReimbushGlassesData.price">
        <label for="example-text-input" class="form-control-label mt-3">Diagnosis <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="medReimbushGlassesData.diagnosis" placeholder="Diagnosis"></textarea>
        <label for="example-text-input" class="form-control-label mt-3">Attachment </label>
        <input type="file" class="form-control" placeholder="Attachment" @change="filesChange" >
        <label for="example-text-input" class="form-control-label mt-3">Is Urgent ? <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="medReimbushGlassesData.is_urgent">
          <option selected>Is Urgent ?</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Claim Number</label>
        <input type="text" class="form-control" placeholder="Claim Number" v-model="search.claim_number" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Employee <span style="color: red;">*</span></label>
         <multiselect v-model="search.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Submission Date</label>
        <input type="date" class="form-control" placeholder="Submission Date" v-model="search.submission_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Receipt Date</label>
        <input type="date" class="form-control" placeholder="Receipt Date" v-model="search.receipt_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Is Urgent ? <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="search.is_urgent">
          <option selected>Is Urgent ?</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Is Closed ? <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="search.is_closed">
          <option selected>Is Closed ?</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {claim_number: '', employee: '', submission_date: '', receipt_date: '', is_urgent: '', is_closed: '',}">Clear</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import medReimbushGlasses from '@/services/medReimbushGlasses.service';
import employee from '@/services/employee.service';


export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      medReimbushGlassesData: {},
      search: {
        claim_number: '',
        employee: '',
        submission_date: '',
        receipt_date: '',
        is_urgent: '',
        is_closed: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalWorkingHour: '',
      totalWorkingMinutes: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, medReimbushGlasses.index({claim_number: this.search.claim_number, employee: this.search.employee['nik'], submission_date: this.search.submission_date, receipt_date: this.search.receipt_date, is_urgent: this.search.is_urgent, is_closed: this.search.is_closed, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getEmployee(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.medReimbushGlassesData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, medReimbushGlasses.show(id)).onSuccess(function(response) {
          context.medReimbushGlassesData          = response.data.data.data;
          context.medReimbushGlassesData.employee = response.data.data.dataEmployee
          context.form.show                       = true;
          context.form.title                      = 'Edit Data';   
      })
      .call()        
    },
    closed(id) {
      let api      = null;
      let context  = this;

      api = Api(context, medReimbushGlasses.closed(id));
      api.onSuccess(function(response) {
        context.get();
        context.notify('Closed Successfully', 'success')
      }).onError(function(error) { 
        context.notify('Closed Failed', 'error')                   
      }).onFinish(function() {  
      })
      .call();       
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.medReimbushGlassesData.employee != undefined && context.medReimbushGlassesData.submission_date != undefined && context.medReimbushGlassesData.receipt_date != undefined && context.medReimbushGlassesData.price != undefined && context.medReimbushGlassesData.diagnosis != undefined && context.medReimbushGlassesData.is_urgent != undefined) {
        formData.append('employee', JSON.stringify(context.medReimbushGlassesData.employee));
        formData.append('submission_date', context.medReimbushGlassesData.submission_date);
        formData.append('receipt_date', context.medReimbushGlassesData.receipt_date);
        formData.append('price', context.medReimbushGlassesData.price);
        formData.append('diagnosis', context.medReimbushGlassesData.diagnosis);
        formData.append('attachment', context.medReimbushGlassesData.attachment);
        formData.append('is_urgent', context.medReimbushGlassesData.is_urgent);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, medReimbushGlasses.create(formData));
      } else {
          api = Api(context, medReimbushGlasses.update(this.medReimbushGlassesData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;
        Api(context, medReimbushGlasses.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.medReimbushGlassesData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
