<template>
  <main v-if="role == 'Admin' || roleIdKaryawan == $route.params.nik">
    <div class="container-fluid">
      <div
        class="page-header min-height-300"
        style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative" v-if="karyawan.foto_karyawan">
                <img
                  :src="storageUrl+'/foto_karyawan/'+karyawan.foto_karyawan"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
              <div class="avatar avatar-xl position-relative" v-else>
                <img
                    src="@/assets/img/avatar_karyawan.png"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ karyawan.name }}</h5>
                <p class="mb-0 font-weight-bold text-sm">{{ karyawan.position }}</p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                  <!-- === ABSEN === -->
                  <li class="nav-item" @click="changeTab('Absen')">
                    <a
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="false"
                    >
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      <span class="ms-1">Absen</span>
                    </a>
                  </li>

                  <!-- === PROFILE === -->
                  <li class="nav-item" @click="changeTab('Profile')">
                    <a
                      class="px-0 py-1 mb-0 nav-link "
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="true"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 42 42"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2319.000000, -291.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(603.000000, 0.000000)">
                                <path
                                  class="color-background"
                                  d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                                />
                                <path
                                  class="color-background"
                                  d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                  opacity="0.7"
                                />
                                <path
                                  class="color-background"
                                  d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                  opacity="0.7"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">Profile</span>
                    </a>
                  </li>

                  <!-- === CUTI === -->
                  <li class="nav-item" @click="changeTab('Cuti')">
                    <a
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="false"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 40 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>document</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-1870.000000, -591.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(154.000000, 300.000000)">
                                <path
                                  class="color-background"
                                  d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                  opacity="0.603585379"
                                />
                                <path
                                  class="color-background"
                                  d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">Cuti</span>
                    </a>
                  </li>

                  <!-- === LEMBUR === -->
                  <li class="nav-item" @click="changeTab('Lembur')">
                    <a
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="false"
                    >
                      <i class="fa fa-briefcase" aria-hidden="true"></i>
                      <span class="ms-1">Lembur</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TAB PROFILE -->
    <div class="py-4 container-fluid" style="margin-top: -0px;" v-if="tab == 'Profile'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <!-- <p class="mb-0">Edit Profile</p> -->
                <argon-button color="success" size="sm" class="ms-auto" @click="ChangePasswordFoto()" v-if="role == 'Users'">Change Password & Foto</argon-button>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Company Information</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">NIK</label>
                  <input disabled type="text" class="form-control" @change="updateProfile()" placeholder="NIK" v-model="karyawan.nik">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Employee Name</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Employee Name" v-model="karyawan.name">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Shift</label>
                  <select class="form-select" aria-label="Default select example" v-model="karyawan.shift" @change="updateProfile()">
                    <option selected>Select Shift</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="Non">Non</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Position</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Position" v-model="karyawan.position">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Department</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Department" v-model="karyawan.department">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Employee Status</label>
                  <select class="form-select" aria-label="Default select example" v-model="karyawan.employee_status" @change="updateProfile()" :disabled="role != 'Admin'">
                    <option value="Permanent">Permanent</option>
                    <option value="Contract">Contract</option>
                    <option value="Daily">Daily</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">OT Status</label>
                  <select class="form-select" aria-label="Default select example" v-model="karyawan.ot_status" @change="updateProfile()" :disabled="role != 'Admin'">
                    <option value="H">H - Jam Hidup</option>
                    <option value="M">M - Jam Mati</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Date Of Hire</label>
                  <input :disabled="role != 'Admin'" type="date" class="form-control" @change="updateProfile()" placeholder="Date Of Hire" v-model="karyawan.date_of_hire">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">End Of Contract</label>
                  <input :disabled="role != 'Admin'" type="date" class="form-control" @change="updateProfile()" placeholder="End Of Contract" v-model="karyawan.end_of_contract">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Basic Hour</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Basic Hour" v-model="karyawan.basic_hour">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Basic Day</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Basic Day" v-model="karyawan.basic_day">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Basic Month</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Basic Month" v-model="karyawan.basic_mounth">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Position Allowance</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="TJ. Jabatan / Skill" v-model="karyawan.position_allowance">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Health Allowance</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Tunjangan JKK" v-model="karyawan.health_allowance">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Pension Allowance</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Status" v-model="karyawan.pension_allowance">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Comunication Allowance</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Comunication Allowance" v-model="karyawan.communication_allowance">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Transport Allowance</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Transport" v-model="karyawan.transport_allowance">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Meal Allowance</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Makan" v-model="karyawan.meal_allowance">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Total Cuti</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Cuti" v-model="karyawan.total_cuti">
                </div>
              </div>

              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Personal Information</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">NO. KTP</label>
                  <input type="text" class="form-control" @change="updateProfile()" placeholder="NO. KTP" v-model="karyawan.no_ktp">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">No Handphone</label>
                  <input type="text" class="form-control" @change="updateProfile()" placeholder="No Handphone" v-model="karyawan.no_hp">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Email</label>
                  <input type="email" class="form-control" @change="updateProfile()" placeholder="Email" v-model="karyawan.email">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Place Of Birth</label>
                  <input type="text" class="form-control" @change="updateProfile()" placeholder="Place Of Birth" v-model="karyawan.place_birth">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Date Of Birth</label>
                  <input type="date" class="form-control" @change="updateProfile()" placeholder="Date Of Birth" v-model="karyawan.date_birth">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Address</label>
                  <input type="text" class="form-control" @change="updateProfile()" placeholder="Address" v-model="karyawan.address">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Gendre</label>
                  <select class="form-select" aria-label="Default select example" v-model="karyawan.gendre" @change="updateProfile()">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Education</label>
                  <input type="text" class="form-control" @change="updateProfile()" placeholder="Education" v-model="karyawan.education">
                </div>
              </div>

              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Rekening Information</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Nama Bank</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Nama Bank" v-model="karyawan.bank">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">No Rekening</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="No Rekening" v-model="karyawan.no_rek">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">AN Rekening</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="AN Rekening" v-model="karyawan.an_rek">
                </div>
              </div>

              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">BPJS & TAX Information</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">No BPJS TK</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="No BPJS TK" v-model="karyawan.no_bpjs_tk">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">No BPJS Kes</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="No BPJS Kes" v-model="karyawan.no_bpjs_kes">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">No NPWP</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="No NPWP" v-model="karyawan.npwp">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">TAX Status</label>
                  <select class="form-select" aria-label="Default select example" v-model="karyawan.tax_status" @change="updateProfile()" :disabled="role != 'Admin'">
                    <option value="K0">K0</option>
                    <option value="K1">K1</option>
                    <option value="K2">K2</option>
                    <option value="K3">K3</option>
                    <option value="TK0">TK0</option>
                    <option value="TK1">KT1</option>
                  </select>
                </div>

                <!-- <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">BPJS</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Upah BPJS" v-model="karyawan.bpjs">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">JHT</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="JHT" v-model="karyawan.jht">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">JP</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="JP" v-model="karyawan.jp">
                </div> -->
                <!-- <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">TAX</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="TAX" v-model="karyawan.tax">
                </div> -->
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Iuran Kematian</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Iuran Kematian" v-model="karyawan.iuran_kematian">
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label mt-3">Borrow</label>
                  <input :disabled="role != 'Admin'" type="text" class="form-control" @change="updateProfile()" placeholder="Borrow" v-model="karyawan.borrow">
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <profile-card />
        </div> -->
      </div>
    </div>

    <!-- TAB ABSEN -->
    <div class="py-4 container-fluid" style="margin-top: -0px;" v-if="tab == 'Absen'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="font-weight-bold">HISTORY ABSENSI</p>
                <argon-button variant="gradient" color="secondary" size="xs" class="ms-auto" @click="filterAbsen()"><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
              <label class="badge badge-warning"> Hari Ini Anda Shift {{ karyawan.shift }}</label>
            </div>
            <div class="card-body">
              <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0 scroll">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr style="background-color: #F0F8FF;">
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">SHIFT</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">DAY</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">DATE</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">CLOCK IN</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">CLOCK OUT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, i) in tableAttLog.data" :key="i">
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{row.shift}} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.date).locale('id').format('dddd') }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.date).locale('id').format('LL') }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{row.clock_in}} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{row.clock_out}} </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="card-footer">
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TAB CUTI -->
    <div class="py-4 container-fluid" style="margin-top: -0px;" v-if="tab == 'Cuti'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-4">
                  <p class="font-weight-bold">HISTORY CUTI / IZIN</p>
                  <p>SISA CUTI &ensp;&nbsp;&ensp;&ensp; :&ensp; {{ (totalCuti) }} Hari</p><br>
                  <p style="margin-top: -40px;">TOTAL SAKIT &ensp;:&ensp; {{ totalSakit }} Hari</p>
                </div>
                <div class="col-lg-4">
                </div>
                <div class="col-lg-4">
                  <div class="float-end">
                    <argon-button color="primary" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="approveCuti()" v-if="tableAppCuti.data.length != 0"><i class="fa fa-bell fa-sm" aria-hidden="true"></i> Approval Cuti / Izin</argon-button>
                    <argon-button color="success" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="createCuti()" ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Ajukan Cuti</argon-button>
                    <argon-button variant="gradient" color="secondary" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="filterCuti()" ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0 scroll">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr style="background-color: #F0F8FF;">
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Alasan</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Dari Tanggal</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Sampai Tanggal</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Total</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Bukti</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Status</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Approved To</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Approved Date</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Dibuat Tanggal</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, i) in tableCuti.data" :key="i">
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.alasan_cuti }} </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.dari_tanggal).locale('id').format('L') }} </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.sampai_tanggal).locale('id').format('L') }} </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.total_day }} Hari</span>
                          </td>
                          <td class="align-middle text-center">
                            <a v-if="row.bukti" :href="storageUrl+'/foto_bukti_sakit/'+row.bukti" target="_BLANK" class="text-xs font-weight-bold text-primary">
                              Bukti Surat Sakit
                            </a>
                          </td>
                          <td class="align-middle text-center">
                            <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                            <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                            <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.approved_to }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.approved_date).locale('id').format('L') }}</span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.created_at).locale('id').format('L') }}</span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="badge badge-sm bg-gradient-info" v-if="row.type == 'Cuti'"> {{ row.type }} </span>
                            <span class="badge badge-sm bg-gradient-danger" v-if="row.type == 'Sakit'"> {{ row.type }} </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="card-footer">
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TAB LEMBUR -->
    <div class="py-4 container-fluid" style="margin-top: -0px;" v-if="tab == 'Lembur'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-4">
                  <p class="font-weight-bold">HISTORY LEMBUR</p>
                  <p>TOTAL JAM LEMBUR &ensp;:&ensp; {{ totalJamLemburMonth }} Jam</p>
                </div>
                <div class="col-lg-4">
                </div>
                <div class="col-lg-4">
                  <div class="float-end">
                    <argon-button color="primary" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="approveLembur()" v-if="tableAppLembur.data.length != 0"><i class="fa fa-bell fa-sm" aria-hidden="true"></i> Approval Lembur</argon-button>
                    <argon-button color="success" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="historySPL()" v-if="role == 'Users' && (karyawan.unit == 'Head Quarter' || karyawan.jabatan.includes('Leader')) "><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Buat SPL</argon-button>
                    <argon-button variant="gradient" color="secondary" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="filterLembur()" v-if="role == 'Users'"><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0 scroll">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr style="background-color: #F0F8FF;">
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Tanggal</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Hari</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Aktual Jam</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Total Jam</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="width: 2px;">Uraian Pekerjaan</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Status</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Approved To</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Approved Date</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Code SPL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, i) in tableLembur.data" :key="i">
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('L') }}</span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('dddd') }} </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.actual_jam }} Jam </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.calculate_jam }} Jam </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.uraian_pekerjaan}} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                            <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                            <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.approved_to }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.approved_date).locale('id').format('L') }}</span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="badge badge-sm bg-gradient-info"> {{ row.code_spl }} </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="card-footer">
              
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- =======  MODAL FILTER ABSEN ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterAbsen.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterAbsen.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterAbsen.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Date</label>
        <input type="date" class="form-control" placeholder="Date" v-model="searchAttLog.date">
        <label for="example-text-input" class="form-control-label mt-3">Shift</label>
        <select class="form-select" aria-label="Default select example" v-model="searchAttLog.shift">
          <option selected>Select Shift</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="Non">Non</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5 mb-3">
        <div class="col-6"> 
        </div>
        <div class="col-2">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterAbsen.show = true">Close</argon-button> -->
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getLogAbsen()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL CHANGE PASSWORD & FOTO ======= -->
  <div class="container">
    <vue-final-modal v-model="formChangePassFoto.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formChangePassFoto.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formChangePassFoto.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Change Foto</label>
        <input type="file" class="form-control" placeholder="Change Foto" @change="filesChange">
        <label for="example-text-input" class="form-control-label mt-3">Change Password</label>
        <input type="text" class="form-control" placeholder="Change Password" v-model="changePassFoto.password">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formChangePassFoto.show = true">Close</argon-button> -->
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="changePassFotoFunct()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL FILTER CUTI / IZIN ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterCuti.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterCuti.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterCuti.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Date</label>
        <input type="date" class="form-control" placeholder="Date" v-model="searchCuti.date">
        <label for="example-text-input" class="form-control-label mt-3">Type</label>
        <select class="form-select" aria-label="Default select example" v-model="searchCuti.type">
          <option selected>Select Type</option>
          <option value="Cuti">Cuti</option>
          <option value="Sakit">Sakit</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="searchCuti.status">
          <option selected>Select Status</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="Waiting">Waiting</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5 mb-3">
        <div class="col-6"> 
        </div>
        <div class="col-2">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterCuti.show = true">Close</argon-button> -->
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getDataCuti()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL AJUKAN CUTI ======= -->
  <div class="container">
    <vue-final-modal v-model="formCuti.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formCuti.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formCuti.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="dataCuti.type">
          <option value="Cuti">Cuti</option>
          <option value="Cuti Setengah Hari">Cuti Setengah Hari</option>
          <option value="Sakit">Sakit</option>
        </select>
        <template v-if="dataCuti.type == 'Sakit'">
          <label for="example-text-input" class="form-control-label mt-3">Upload Bukti <span style="color: red;">*</span></label>
          <input type="file" class="form-control" placeholder="Upload Bukti" @change="filesChangeBukti" style="margin-bottom: 20px;">
        </template>
        <label for="example-text-input" class="form-control-label mt-3">Alasan <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="dataCuti.alasan_cuti"></textarea>
        <label for="example-text-input" class="form-control-label mt-3">Dari Tanggal <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Dari Tanggal" v-model="dataCuti.dari_tanggal" :min="moment().locale('id').format('YYYY-MM-Do')">
        <label for="example-text-input" class="form-control-label mt-3">Sampai Tanggal <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Sampai Tanggal" v-model="dataCuti.sampai_tanggal" :min="moment().locale('id').format('YYYY-MM-Do')">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formCuti.show = true">Close</argon-button> -->
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveCuti()" :disabled="onLoading == true || ((dataCuti.type == 'Cuti' || dataCuti.type == 'Cuti Setengah Hari') && totalCuti == 0.0)">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else-if="((dataCuti.type == 'Cuti' || dataCuti.type == 'Cuti Setengah Hari') && totalCuti == 0.0)">Batas Cuti Habis</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL APPROVAL CUTI ======= -->
  <div class="container">
    <vue-final-modal v-model="formAppCuti.show" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAppCuti.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAppCuti.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="table-responsive p-0 scroll">
          <table class="table align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Dibuat Oleh</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Tanggal Dibuat</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Type</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Alasan</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Dari Tanggal</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Sampai Tanggal</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Total</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Bukti</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Status</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Konfirm</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableAppCuti.data" :key="i">
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.dibuat_oleh }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.created_at).locale('id').format('L') }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-success" v-if="row.type == 'Cuti'"> {{ row.type }} </span>
                  <span class="badge badge-sm bg-gradient-danger" v-if="row.type == 'Sakit'"> {{ row.type }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.alasan_cuti }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.dari_tanggal).locale('id').format('L') }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.sampai_tanggal).locale('id').format('L') }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.total_day }} Hari</span>
                </td>
                <td class="align-middle text-center">
                  <a v-if="row.bukti" :href="storageUrl+'/foto_bukti_sakit/'+row.bukti" target="_BLANK" class="text-xs font-weight-bold text-primary">
                    Bukti Surat Sakit
                  </a>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                  <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                  <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                </td>
                <td class="align-middle text-center" v-if="((row.dibuat_oleh != karyawan.nama) || (row.approved_to == karyawan.nama)) && (row.status_approval == 'Waiting')">
                  <argon-button color="success" size="xs" class="ms-auto" style="margin-right: 8px;" @click="konfirmasiCuti(row.id, 'Approved')"> Approved</argon-button>
                  <argon-button color="danger" size="xs" class="ms-auto" @click="konfirmasiCuti(row.id, 'Not Approved')"> Not Approved</argon-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-final-modal>
  </div>

  <!-- =======  MODAL FILTER LEMBUR ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterLembur.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterLembur.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterLembur.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Date</label>
        <input type="date" class="form-control" placeholder="Date" v-model="searchLembur.date">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="searchLembur.status">
          <option selected>Select Status</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="Waiting">Waiting</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Code SPL</label>
        <input type="text" class="form-control" placeholder="Code SPL" v-model="searchLembur.code_spl">
        <label for="example-text-input" class="form-control-label mt-3">Periode Start</label>
        <input type="date" class="form-control" placeholder="Periode Start" v-model="searchLembur.periode_start">
        <label for="example-text-input" class="form-control-label mt-3">Periode End</label>
        <input type="date" class="form-control" placeholder="Periode End" v-model="searchLembur.periode_end">
      </div>
      <!-- footer -->
      <div class="row float-right mt-5 mb-3">
        <div class="col-6"> 
        </div>
        <div class="col-2">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterLembur.show = true">Close</argon-button> -->
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getDataLembur()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL SPL BER DASARKAN YANG BUAT NYA ======= -->
  <div class="container">
    <vue-final-modal v-model="formHisSpl.show" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formHisSpl.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formHisSpl.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <argon-button color="success" size="xs" class="mb-3" @click="createSPL()"><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Buat SPL</argon-button>
        <div class="table-responsive p-0 scroll">
          <table class="table align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Dibuat Oleh</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Tanggal Dibuat</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Tanggal Lembur</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Aktual Jam</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Total Anggota</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Status</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Code SPL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableHisLembur.data" :key="i">
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.created_by }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.created_at).locale('id').format('L') }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('L') }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.actual_jam }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.total_anggota }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                  <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                  <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-info" style="cursor: pointer;" @click="showDetailSPL(row.code_spl)"> {{ row.code_spl }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-final-modal>
  </div>

  <!-- =======  MODAL CREATE SPL ======= -->
  <div class="container">
    <vue-final-modal v-model="formSPL.show" classes="modal-container" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row d-none d-md-block">
        <div class="col-11 float-left">
          <span class="modal__title">{{formSPL.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formSPL.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="row">
          <div class="col">
            <div class="float-end">
              <a class="btn btn-xs btn-warning d-none d-md-block" :href="apiUrl+'print-pdf/spl?code_spl='+dataSPL.code_spl" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print</a>
            </div>
          </div>
        </div>
        <h5 class="text-center font-weight-bold mb-3" style="margin-top: -50px;">Surat Perintah Lembur</h5>
        <hr class="horizontal dark mb-3" />
        <p>Dengan ini di perintahkan untuk melaksanakan pekerjaan lembur di luar hari/jam kerja</p>
        <p>Code SPL &ensp;&nbsp;&ensp;&nbsp;&ensp; : <input style="border: 1px solid white;" v-model="dataSPL.code_spl" size="8" @change="getAnggotaToTableSPL()"></p>
        <p style="margin-top: -13px;">
          Tanggal &ensp;&nbsp;&ensp;&nbsp;&ensp;&nbsp;&ensp;&nbsp;: <input type="date" style="border: 1px solid white;" v-model="dataSPL.tanggal" :disabled="tableAnggotaToTableSPL.data.length !== 0" :min="moment().locale('id').format('YYYY-MM-Do')">
        </p>
        <p style="margin-top: -13px;">
          Total Aktual Jam &ensp;: <input style="border: 1px solid white;" v-model="dataSPL.jam" size="2" :disabled="tableAnggotaToTableSPL.data.length !== 0"> Jam
        </p>
        <p style="margin-top: -13px;">
          Uraian Pekerjaan : <textarea style="border: 1px dotted dimgray; border-radius: 15px 15px 15px 15px;; resize: none; width: 100%;" placeholder="Masukkan Uraian Pekerjaan ...." rows="5" v-model="dataSPL.uraian_pekerjaan" :disabled="tableAnggotaToTableSPL.data.length !== 0"></textarea>
        </p>
        <div>
          <span class="text-center">Dengan Anggota Sebagai Berikut</span><br>
          <argon-button :disabled="(dataSPL.tanggal == undefined) && (dataSPL.jam == undefined)" color="success" size="xs" class="justify-content-end mb-2" @click="tambahAnggotaLembur()" v-if="role == 'Users'"><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Tambah Anggota</argon-button>
        </div>
        <div class="table-responsive p-0 scroll">
          <table class="table align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">NAMA</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">POSISI</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">UNIT</th>
                <th v-if="tableAnggotaToTableSPL.data.length !== 0"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableAnggotaToTableSPL.data" :key="i">
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.nama }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.posisi }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.unit }} </span>
                </td>
                <td style="text-align: center;" v-if="row.created_by == roleFullName">
                  <i class="fa fa-times-circle fa-lg" aria-hidden="true" title="Delete" style="cursor: pointer;" @click="removeAnggota(row.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- footer -->
      <div class="row float-right">
        <div class="col-8"> 
        </div>
        <div class="col-4">
          <argon-button color="success" variant="gradient" size="xs mb-4" width="1" @click="generateRandomString(), dataSPL = {}, formSPL.show = false"><i class="fa fa-check" aria-hidden="true"></i> Selesai</argon-button>
        </div>
      </div>
    </vue-final-modal>
  </div>

  <!-- =======  MODAL TAMBAH ANGGOTA LEMBUR ======= -->
  <div class="container">
    <vue-final-modal v-model="formAnggotaLembur.show" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAnggotaLembur.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAnggotaLembur.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Search Anggota </label>
        <input type="text" class="form-control mb-3" placeholder="Keyword : Nama, Posisi/Jabatan, Unit" v-model="searchAnggota" @keyup="getAnggota()">
        <div class="table-responsive p-0 scroll">
          <table class="table align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">NAMA</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">POSISI</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">UNIT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableSearchAnggota.data" :key="i">
                <td class="align-middle text-left text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.nama }} </span>
                </td>
                <td class="align-middle text-left">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.jabatan }} </span>
                </td>
                <td class="align-middle text-left">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.unit }} </span>
                </td>
                <td>
                  <i class="fa fa-plus-square text-primary" title="Add Anggota" style="cursor: pointer;" @click="addSPL(row.nik)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL APPROVAL LEMBUR ======= -->
  <div class="container">
    <vue-final-modal v-model="formAppLembur.show" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAppLembur.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAppLembur.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="table-responsive p-0 scroll">
          <table class="table align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Dibuat Oleh</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Tanggal Dibuat</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Code SPL</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">HARI</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Tanggal Lembur</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Aktual Jam</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Total Anggota</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Status</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Konfirm</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableAppLembur.data" :key="i">
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.created_by }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.created_at).locale('id').format('L') }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-info" style="cursor: pointer;" @click="showDetailSPL(row.code_spl)"> {{ row.code_spl }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('dddd') }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('L') }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.actual_jam }} </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold"> {{ row.total_anggota }} </span>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                  <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                  <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                </td>
                <td class="align-middle text-center" v-if="((row.created_by != karyawan.nama) || (row.approved_to == karyawan.nama)) && (row.status_approval == 'Waiting')">
                  <argon-button color="success" size="xs" class="ms-auto" style="margin-right: 8px;" @click="konfirmasiLembur(row.code_spl, 'Approved')"> Approved</argon-button>
                  <argon-button color="danger" size="xs" class="ms-auto" @click="konfirmasiLembur(row.code_spl, 'Not Approved')"> Not Approved</argon-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-final-modal>
  </div>

  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";
import config from '@/configs/config';
import Api from '@/helpers/api';
import { VueFinalModal } from 'vue-final-modal';
var moment = require('moment');
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import employee from '@/services/human_resource/employee.service';
import approvalCuti from '@/services/human_resource/approvalCuti.service';
import approvalLembur from '@/services/human_resource/approvalLembur.service';
import akun from '@/services/akun.service';
import dataAbsensi from '@/services/human_resource/dataAbsensi.service';

const body = document.getElementsByTagName("body")[0];

export default {
  name: "profile",
  data() {
    return {
      moment:moment,
      showMenu: false,
      onLoading: false,
      karyawan: {},
      tab: 'Absen',
      formFilterAbsen: {
        add: true,
        title: "Filter Absen",
        show: false
      },
      formFilterCuti: {
        add: true,
        title: "Filter Cuti Izin",
        show: false
      },
      formFilterLembur: {
        add: true,
        title: "Filter Lembur",
        show: false
      },
      formCuti: {
        add: true,
        title: "Form Pengajuan Cuti / Izin",
        show: false
      },
      formHisSpl: {
        add: true,
        title: "SPL Yang Anda Buat",
        show: false
      },
      formSPL: {
        add: true,
        title: "Form Surat Perintah Lembur",
        show: false
      },
      formAnggotaLembur: {
        add: true,
        title: "Tambah Anggota Lembur",
        show: false
      },
      formAppCuti: {
        add: true,
        title: "Form Approval Cuti",
        show: false
      },
      formAppLembur: {
        add: true,
        title: "Form Approval Lembur",
        show: false
      },
      formChangePassFoto: {
        add: true,
        title: "Form Change Password & Foto",
        show: false
      },
      changePassFoto: {},
      dataCuti: {
        tanggal: '',
        jam: '',
      },
      dataSPL: {},
      tableAttLog: {
        data: []
      },
      tableCuti: {
        data: []
      },
      tableAppCuti: {
        data: []
      },
      tableHisLembur: {
        data: []
      },
      tableAppLembur: {
        data: []
      },
      tableLembur: {
        data: []
      },
      tableSearchAnggota: {
        data: []
      },
      tableAnggotaToTableSPL: {
        data: []
      },
      totalCuti: '',
      totalSakit: '',
      totalJamLemburMonth: '',
      role: '',
      roleIdKaryawan: '',
      roleFullName: '',
      storageUrl : config.storageUrl,
      searchAnggota: '',
      apiUrl :config.apiUrl,
      searchAttLog: {
        shift: '',
        date: '',
        type: '',
      },
      searchCuti: {
        date: '',
        type: '',
        status: '',
      },
      searchLembur: {
        date: '',
        status: '',
        code_spl: '',
        periode_start: '',
        periode_end: '',
      }
    };
  },
  components: { 
    ArgonButton,
    VueFinalModal,
  },
  mounted() {
    this.getDataProfile();
    this.getLogAbsen();
    this.getRole();
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  methods: {
    getDataProfile() {
      let context = this;               
      Api(context, employee.show(context.$route.params.nik)).onSuccess(function(response) {
          context.karyawan  = response.data.data;    
          context.totalCuti = response.data.data.total_cuti;
      })
      .call()        
    },
    getLogAbsen(){
      let context = this;

      Api(context, dataAbsensi.attLogIdKaryawan({nik: context.$route.params.nik, shift: context.searchAttLog.shift, date: context.searchAttLog.date, type: context.searchAttLog.type})).onSuccess(function(response) {
          context.tableAttLog.data = response.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableAttLog.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
          context.formFilterAbsen.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;     
      context.onLoading = true;          
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role           = response.data.data[0].role;
          context.roleIdKaryawan = response.data.data[0].employee_id;
          context.roleFullName   = response.data.data[0].full_name;
          console.log(response.data.data[0])
      }).onError(function(error) {  
      })
      .call() 
    },
    updateProfile(){
      let api     = null;
      let context = this;

      api = Api(context, employee.update(context.karyawan.id, {
          // company information
          nik: context.karyawan.nik,
          name: context.karyawan.name,
          position: context.karyawan.position,
          department: context.karyawan.department,
          employee_status: context.karyawan.employee_status,
          ot_status: context.karyawan.ot_status,
          date_of_hire: context.karyawan.date_of_hire,
          end_of_contract: context.karyawan.end_of_contract,
          basic_hour: context.karyawan.basic_hour,
          basic_day: context.karyawan.basic_day,
          basic_mounth: context.karyawan.basic_mounth,
          position_allowance: context.karyawan.position_allowance,
          health_allowance: context.karyawan.health_allowance,
          pension_allowance: context.karyawan.pension_allowance,
          communication_allowance: context.karyawan.communication_allowance,
          transport_allowance: context.karyawan.transport_allowance,
          meal_allowance: context.karyawan.meal_allowance,

          // personal information
          no_ktp: context.karyawan.no_ktp,
          no_hp: context.karyawan.no_hp,
          email: context.karyawan.email,
          place_birth: context.karyawan.place_birth,
          date_birth: context.karyawan.date_birth,
          address: context.karyawan.address,
          gendre: context.karyawan.gendre,
          education: context.karyawan.education,

          // bank information
          bank: context.karyawan.bank,
          no_rek: context.karyawan.no_rek,
          an_rek: context.karyawan.an_rek,

          // bpjs & tax information
          no_bpjs_tk: context.karyawan.no_bpjs_tk,
          no_bpjs_kes: context.karyawan.no_bpjs_kes,
          npwp: context.karyawan.npwp,
          tax_status: context.karyawan.tax_status,

          bpjs: context.karyawan.bpjs,
          jht: context.karyawan.jht,
          jp: context.karyawan.jp,
          tax: context.karyawan.tax,
          iuran_kematian: context.karyawan.iuran_kematian,
          borrow: context.karyawan.borrow,

          shift: context.karyawan.shift,
          total_cuti: context.karyawan.total_cuti,
        }));
      api.onSuccess(function(response) {
          context.notify('Update Success', 'success')
      }).onError(function(error) { 
          context.notify('Update Failed', 'error')
      }).onFinish(function() {  
      })
      .call();
    },
    changeTab(tab){
      this.tab = tab
      if (tab == 'Absen') {
        this.getLogAbsen();
        this.searchAttLog = {}
      }else if(tab == 'Profile'){
        this.getDataProfile();
      }else if(tab == 'Cuti'){
        this.getDataCuti();
        this.searchCuti = {}
      }else if(tab == 'Lembur'){
        this.getDataLembur();
        this.searchLembur = {}
      }
    },
    ChangePasswordFoto() {
      this.formChangePassFoto.add   = true;
      this.formChangePassFoto.show  = true;
      this.formChangePassFoto.title = "Form Change Password & Foto";
      this.onLoading = false;
    },
    filesChange(e) {
        this.changePassFoto.foto = e.target.files[0];
    },
    filesChangeBukti(e) {
        this.dataCuti.bukti = e.target.files[0];
    },
    changePassFotoFunct(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      formData.append('nik', this.karyawan.id);
      formData.append('employee_id', this.karyawan.nik);
      formData.append('foto', (this.changePassFoto.foto == undefined) ? '' : this.changePassFoto.foto);
      formData.append('password', (this.changePassFoto.password == undefined) ? '' : this.changePassFoto.password);

      api = Api(context, employee.changePassFoto(formData));
      api.onSuccess(function(response) {
          context.notify('Change Data Success', 'success')
      }).onError(function(error) {                   
          context.notify('Change Data Failed', 'error')
      }).onFinish(function() {  
          context.onLoading = false;
          context.get();
          context.formChangePassFoto.show = false;
      })
      .call();
    },
    createCuti() {
      this.formCuti.add   = true;
      this.formCuti.show  = true;
      this.formCuti.title = "Form Pengajuan Cuti / Izin";
      this.dataCuti       = {};
      this.onLoading      = false;
    },
    historySPL() {
      this.formHisSpl.add   = true;
      this.formHisSpl.show  = true;
      this.formHisSpl.title = "SPL Yang Anda Buat";
      // this.dataSPL       = {};
      this.onLoading     = false;
    },
    createSPL() {
      this.formSPL.add   = true;
      this.formSPL.show  = true;
      this.formSPL.title = "Form Surat Perintah Lembur";
      // this.dataSPL       = {};
      this.onLoading     = false;

      if (this.dataSPL.code_spl == undefined) {
        this.generateRandomString();
      }
    },
    tambahAnggotaLembur() {
      this.formAnggotaLembur.add   = true;
      this.formAnggotaLembur.show  = true;
      this.formAnggotaLembur.title = "Tambah Anggota Lembur";
      this.onLoading               = false;
      this.getAnggotaToTableSPL()
    },
    approveCuti() {
      this.formAppCuti.add   = true;
      this.formAppCuti.show  = true;
      this.formAppCuti.title = "Form Approval Cuti";
      this.onLoading         = false;
    },
    approveLembur() {
      this.formAppLembur.add   = true;
      this.formAppLembur.show  = true;
      this.formAppLembur.title = "Form Approval Lembur";
      this.onLoading         = false;
    },
    saveCuti(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      this.onLoading = true;

      if (context.dataCuti.type != undefined && context.dataCuti.alasan_cuti != undefined && context.dataCuti.dari_tanggal != undefined && context.dataCuti.sampai_tanggal != undefined) {
        formData.append('nik', context.karyawan.nik);
        formData.append('dibuat_oleh', context.karyawan.name);
        formData.append('type', context.dataCuti.type);
        formData.append('alasan_cuti', context.dataCuti.alasan_cuti);
        formData.append('dari_tanggal', context.dataCuti.dari_tanggal);
        formData.append('sampai_tanggal', context.dataCuti.sampai_tanggal);
      }else{
        context.onLoading = false;
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      if (context.dataCuti.type == 'Sakit') {
        if (context.dataCuti.bukti != undefined) {
          formData.append('bukti', context.dataCuti.bukti);
        }else{
          context.onLoading = false;
          return alert('Bukti Wajib Di Isi')
        }
      }

      if (context.formCuti.title == 'Form Pengajuan Cuti / Izin') {
          api = Api(context, approvalCuti.create(formData));
      } else {
          api = Api(context, approvalCuti.update(this.dataCuti.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getDataCuti();
        context.formCuti.show = false;
        context.notify((context.formCuti === 'Form Pengajuan Cuti / Izin') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formCuti != 'Form Pengajuan Cuti / Izin') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {
        context.onLoading = false;
      })
      .call();
    },
    getDataCuti() {
      let context = this;               
      Api(context, approvalCuti.index({date: context.searchCuti.date, type: context.searchCuti.type, status: context.searchCuti.status, nik: context.$route.params.nik})).onSuccess(function(response) {
          context.tableCuti.data    = response.data.data.data;       
          context.tableAppCuti.data = response.data.data.dataApproval;       
          // context.totalCuti         = response.data.data.totalCuti;       
          context.totalSakit        = response.data.data.totalSakit;       
      }).onFinish(function() {
        context.formFilterCuti.show = false;
      })
      .call()        
    },
    konfirmasiCuti(id, type){
      let api     = null;
      let context = this;

      api = Api(context, approvalCuti.update(id, {
          type: type,
        }));
      api.onSuccess(function(response) {
          context.notify('Update Success', 'success')
      }).onError(function(error) { 
          context.notify('Update Failed', 'error')
      }).onFinish(function() { 
        context.getDataCuti(); 
      })
      .call();
    },
    konfirmasiLembur(code_spl, type){
      let api     = null;
      let context = this;

      api = Api(context, approvalLembur.appLembur(code_spl, {
          type: type,
        }));
      api.onSuccess(function(response) {
          context.notify('Update Success', 'success')
      }).onError(function(error) { 
          context.notify('Update Failed', 'error')
      }).onFinish(function() { 
        context.getDataLembur(); 
        // context.formAppLembur = false
      })
      .call();
    },
    getDataLembur() {
      let context = this;               
      Api(context, approvalLembur.index({date: context.searchLembur.date, status: context.searchLembur.status, code_spl: context.searchLembur.code_spl, periode_start: context.searchLembur.periode_start, periode_end: context.searchLembur.periode_end,})).onSuccess(function(response) {
          context.tableLembur.data    = response.data.data.data;                
          context.tableHisLembur.data = response.data.data.dataHisLembur;                
          context.tableAppLembur.data = response.data.data.dataApproval;               
          context.totalJamLemburMonth = response.data.data.totalJamLemburMonth;       
      }).onFinish(function() {
        context.formFilterLembur.show = false;
      })
      .call()        
    },
    generateRandomString(){
      // GENERATE RANDOM STRING
      let length = 8;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }

      this.dataSPL.code_spl = result;
      this.getAnggotaToTableSPL();
    },
    getAnggota(){
      let context = this;               
      Api(context, approvalLembur.searchAnggota({search : context.searchAnggota})).onSuccess(function(response) {
          context.tableSearchAnggota.data = response.data.data;     
      })
      .call()
    },
    addSPL(nik){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      this.onLoading = true;

      if (context.dataSPL.tanggal != undefined && context.dataSPL.jam != undefined) {
        formData.append('nik', nik);
        formData.append('code_spl', context.dataSPL.code_spl);
        formData.append('tanggal', context.dataSPL.tanggal);
        formData.append('jam', context.dataSPL.jam);
        formData.append('uraian_pekerjaan', context.dataSPL.uraian_pekerjaan);
      }else{
        context.onLoading = false;
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      api = Api(context, approvalLembur.create(formData));

      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getDataCuti();
        context.notify((context.formSPL === 'Form Surat Perintah Lembur') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formSPL != 'Form Surat Perintah Lembur') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {
        context.onLoading = false;
        context.getDataLembur()
        context.getAnggotaToTableSPL()
      })
      .call();
    },
    getAnggotaToTableSPL(){
      let context = this;               
      Api(context, approvalLembur.show({code_spl : context.dataSPL.code_spl})).onSuccess(function(response) {
          context.tableAnggotaToTableSPL.data = response.data.data;
          context.dataSPL.tanggal             = response.data.data[0].tanggal
          context.dataSPL.jam                 = response.data.data[0].actual_jam
          context.dataSPL.uraian_pekerjaan    = response.data.data[0].uraian_pekerjaan 
      })
      .call()
    },
    removeAnggota(id) {
        var r = confirm("Anda yakin ingin menghapus data ini ?");
        if (r == true) {
          let context = this;

          Api(context, approvalLembur.deleteAnggota(id)).onSuccess(function(response) {
              context.getAnggotaToTableSPL();
              context.getAnggota();
              context.notify('Data Berhasil di Hapus', 'success')
          }).call();
        }
    },
    showDetailSPL(code_spl){
      this.formAppLembur.show  = false;

      this.formSPL.add      = true;
      this.formSPL.show     = true;
      this.formSPL.title    = "Form Surat Perintah Lembur";
      this.dataSPL.code_spl = code_spl;
      this.onLoading        = false;

      this.getAnggotaToTableSPL()
    },
    filterAbsen() {
      this.formFilterAbsen.add   = true;
      this.formFilterAbsen.show  = true;
      this.formFilterAbsen.title = "Filter Absen";
      this.onLoading = false;
    },
    filterCuti() {
      this.formFilterCuti.add   = true;
      this.formFilterCuti.show  = true;
      this.formFilterCuti.title = "Filter Cuti / Izin";
      this.onLoading = false;
    },
    filterLembur() {
      this.formFilterLembur.add   = true;
      this.formFilterLembur.show  = true;
      this.formFilterLembur.title = "Filter Lembur";
      this.onLoading = false;
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  /*width: 1090px;*/
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
